const useOtherMethods = () =>{
    const verifyRecaptcha = async (token: string) =>{
        const response = await fetch(
            `/api/recaptcha?token=${String(token)}`
        ).then((response) => response.json());
        return response.data ?? false;
    };

    const verifyReferenceToUploadDocument = async (referenceNumber: string, lastName: string, referenceType: string) =>{
        const response = await fetch(
            `/api/deal?referenceNumber=${String(referenceNumber)}&lastName=${lastName}&referenceType=${referenceType}`
        ).then((response) => response.json());
        return response.data ?? false;
    };
    const getBase64 = (file: File) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    const uploadFile = async (dealNumber: string, fileId: string, file: File) =>{
        const formData = new FormData();
        formData.append('file', file);
        await fetch(`/api/uploadFile?dealNumber=${dealNumber}&fileId=${fileId}`,
            { 
                method: 'POST',
                body: formData,
                headers: { 'Content-Type': 'multipart/form-data' },
            }
        ).then((response) => console.log(response));
        return false;
    };

    return { verifyRecaptcha, verifyReferenceToUploadDocument, uploadFile, getBase64 };
};

export default useOtherMethods;